<template>
  <div id="jcom-dashboard">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <v-row wrap>
            <v-col align="center" cols="12" sm="12" md="12">
              <v-btn
                @click.prevent="refreshPageData"
                color="#4285f4"
                elevation="30"
                shaped
                tile
                medium
                class="btn btn-primary font-size-h6 px-6 py-4 my-3 mr-3 white--text"
              >
                Refresh
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-data-iterator
            :items="tableData1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:header> </template>
            <template v-slot:default="props">
              <v-row>
                <v-col
                  cols="12"
                  xl="3"
                  md="3"
                  sm="6"
                  v-for="item in props.items"
                  :key="item.MenuId"
                >
                  <v-card raised hover elevation="25" id="menu-list">
                    <v-card-text>
                      <v-row wrap>
                        <v-col sm="4" md="4" lg="4" xl="4" class="text-center">
                          <i
                            class="menu-icon fa-4x"
                            :class="item.MenuIcon"
                            :style="`color:${item.MenuColor}`"
                          ></i>
                        </v-col>
                        <v-col sm="8" md="8" lg="8" xl="8" class="text-center">
                          <h6 id="card-text">
                            {{ item.MenuName }}
                          </h6>
                          <router-link
                            v-if="item.MenuLink != ''"
                            :to="item.MenuLink"
                          >
                            <v-tooltip right max-width="60%" allow-overflow>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                  <v-icon> mdi-eye </v-icon>
                                </span>
                              </template>
                              <span> View {{ item.MenuName }} </span>
                            </v-tooltip>
                          </router-link>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      alert: {},

      valid: true,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],

      ResultFlag: false,
      LoadingFlag: false,

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      tableSelectFlag1: false,
      selected: [],
      search: "",
      TotalMembers: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.CurrentYearId = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
  },
  created() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_dashboard",
        Action: "dashboard",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);

      this.pageData();
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      var LomCode = this.LomCode;
      console.log("LomCode = " + LomCode);

      if (LomCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/jcom_dashboard_menu_lists";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.tableData1 = records;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#jcom-dashboard {
  #menu-list {
    height: 100px;
  }
  #card-text {
    color: black;
  }
  .v-icon {
    color: #f19a3e;
  }
}
</style>
